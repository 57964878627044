@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap');
@import '_header.scss';
@import '_footer.scss';
@import '_menu.scss';
@import '_card.scss';
@import '_punsh.scss';

/* $base-color: rgb(9, 5, 10); */
/* $base-color: #21211F; */
$base-color: #141413; 
$base-color2: rgb(228, 235, 240);
$harmoni_color: rgb(144,161,199);
/* $complement_color: rgb(176,146,152); */
/* $complement_color: #CD3C2A; */
/* $complement_color: #E26D5C; */
/* $complement_color: #da5641; */
$complement_color: #f12a0b;
$font-color: rgb(199, 182, 144);

/* css-reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
    
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/*  */


body{
    background-color: $base-color;
	color: $font-color;
    width: 100%;
    /* height: 100vh; */
    
    font-family: 'Nunito Sans', sans-serif;
}

a{
	text-decoration: none;
}

/* ************************************** */
/* ************ HERO BILDEN ************* */
/* ************************************** */
.hero{
	background-image: url(../img/hero_land.webp);
    height: 40vh;
	width: 100%;
	position: absolute;
	top: 0;
    background-position:left;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: $base-color 0px -30px 20px 12px inset;
	z-index: -1;
}

.heroPortfolio{
	@extend .hero;
	background-image: url(../img/portfolio4.webp);
}

.heroContact{
	@extend .hero;
	background-image: url(../img/contact.webp);
}

.heroAbout{
	@extend .hero;
	background-image: url(../img/about.webp);
}

/* ************************************** */
/* ************ Container *************** */
/* ************************************** */
.flexContainer{   /* hela en components innehåll */
	margin: 100px auto 50px auto;
	width: 80%;
	height: auto;
	/* display: flex;
	flex-wrap: wrap;
	justify-content: space-between; */

}

.article{
	flex-direction: column;
	width: 80%;
	height: auto;
	margin: 100px auto 100px auto;
	display: flex;
	justify-content: space-evenly;
	gap: 50px;
	align-items: center;
}
.titles{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 80px;
	margin-bottom: 50px;
}
.articleTitle{
	font-family: 'Marcellus SC', serif;
	font-size: 60px;
	text-align: center;
	
	color: $base-color2;
}
.articleTitleSecond{
	@extend .articleTitle;
	font-size: 30px;
	font-family: 'Nunito Sans', sans-serif;
	
}
.buttonContainer{
		/* background-color: $base-color2;
		background-color: $harmoni_color; */
		background-color: $complement_color;
		text-decoration: none;
		font-weight: 900;
		letter-spacing:3px;
		text-align:center;
		padding: 10px;
		width: 97%;
		/* height: 40px; */
		/* align-self: flex-end; */
		/* align-self: flex-start; */
		border-radius: 40px;;
		outline: 2px solid $complement_color;
		outline-offset: 5px;
		margin-top: 50px;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset,
    rgba(0, 0, 0, 0.356) -2.4px -2.4px 3.2px inset;
}
.articleButton{
	color: white;   /*  $base-color; */
	font-size: 30px;
}
.buttonContainer2{
	@extend .buttonContainer;
	width: 180px;
	height: 20px;
	margin-left: auto;
	
}
.articleButton2{
	color: white; /* $base-color; */
	font-size: 20px;
}

.miniFlex{
	width: 400px;
	height: 329px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.miniFlex2{
	@extend .miniFlex;
	width: 97%;
	margin-bottom:-200px;
}
.articleContent{
	display: flex;
	flex-direction: row;
	justify-content:center;
	gap:80px;
	max-width: 900px;
}
.selfImage{
	width: 350px;
	height: 250px;
} 
.articleContent p{
	line-height: 1.2rem;
	margin: 10px 0 10px 0;
}

.dividerContainer{
	margin: 150px auto 50px auto;
	width: 100%;
	display: flex;
	justify-content: center;
	/* height: 30px; */
	max-width: 1200px;
}

.divider{
	width: 80%;
	opacity: 0.8;
}

.about{
	gap: 20px;
	width: 80%;
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	justify-content: space-between;
	align-items: flex-start;
}
.aboutContent{
	line-height: 1.5rem;
	letter-spacing: 0.05rem;
	font-size: 1.2rem;
	max-width: 400px;
	margin-top:-6px;
}


@media screen and (max-width: 920px){
	.articleContent{
		flex-direction: column;
		gap:10px;
		
	}
	.selfImage{
		width: 350px;
		height: 350px;
		margin: 0 auto;
	} 
	.buttonContainer2{
		margin-top: 100px;
		width: 97%;
		height: auto;
		margin-left: 0;
		color: white;
	}
	.articleButton2{
		color: $base-color;
		font-size: 30px;
		color: white;
	}
	.miniFlex2{
		margin-bottom:0px;
	}
	.miniFlex{
		
		width: 100%;
		height: auto;
		/* height: 329px;
		display: flex;
		flex-direction: column;
		justify-content: space-around; */
	}
	
 }
 @media screen and (max-width: 834px){
	.about{
		flex-direction: column;
		
	}
	.aboutContent{
		margin-top:-120px;
	}
 }
 
 @media screen and (max-width: 576px){
	.titles{
		
		height: 80px;
		margin-bottom:50px;
	}
	.articleTitleSecond{
		margin-top:20px;
	}
	.articleTitle{
		font-size: 40px;
	}
	.articleTitleSecond{
		font-size: 25px;
	}
	
 }


 /* Huawei P Smart */
 @media screen and (max-width: 360px){
	.hero, .heroPortfolio, .heroContact, .heroAbout{
		height: 200px;
	}

	.titles{
		margin-bottom: 90px;
	}
 }

 