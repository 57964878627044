@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap");
/* base-color2 */
/* $font-color: rgb(99,91,70); */
@import url("https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap");
.footerWrapper {
  width: 100%;
  height: 220px;
  background-color: #241718;
  margin-top: 100px;
  padding-top: 50px;
}

.footerContainer {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin: 0 auto;
  padding: 20px 0 10px 0;
  gap: 20px;
}

.footerItem {
  min-width: 180px;
  width: 200px;
  height: 180px;
  text-align: left;
  padding: 10px;
  color: #e4ebf0;
  line-height: 1.3rem;
}

a {
  text-decoration: none;
  color: #e4ebf0;
}

@media screen and (max-width: 576px) {
  .footerWrapper {
    height: 300px;
  }
  .footerContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0;
  }
  .footerItem {
    height: 120px;
    width: auto;
    text-align: center;
  }
}

/* $font-color: rgb(99,91,70); */
/* $harmoni_color: rgb(144,161,199); */
.menuMain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.menuContainer {
  z-index: 1;
  width: auto;
  height: 35vh;
  /* samma som herobild minus skugga. Ca-värde */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  /* font-family: 'Nunito Sans', sans-serif; */
  font-size: 20px;
  margin-right: 60px;
  text-align: right;
}

.ham {
  display: none;
}

.allnavs, .navItem, .navItemOn {
  text-decoration: none;
  font-weight: 900;
  letter-spacing: 5px;
}

.navItem {
  color: #e4ebf0;
}

.navItemOn {
  color: black;
}

/* 768 */
@media screen and (max-width: 920px) {
  .menuContainer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    font-size: 20px;
    gap: 5px;
    width: 100%;
    margin-right: 0;
    height: 30px;
    margin-top: 400px;
  }
  .navItemOn {
    color: #e4ebf0;
  }
}

@media screen and (max-width: 576px) {
  .menuContainer {
    display: none;
  }
  .menuContainer2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 25px;
    margin-top: 390px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    font-size: 40px;
    height: 300px;
    text-align: center;
    margin-top: 20px;
    line-height: 3.5rem;
    margin-bottom: -100px;
  }
  .ham {
    display: inline;
    margin: 0 auto;
    margin-top: 380px;
    margin-bottom: -50px;
    width: 100%;
  }
  .menuHam {
    margin: 0 auto;
    width: 35px;
    cursor: pointer;
    /* margin-left: auto; */
  }
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .menuHamClose {
    cursor: pointer;
    width: 35px;
    height: 35px;
    border: 3px solid white;
    border-radius: 18px;
    margin: 0 auto;
  }
}

/* Huawei P Smart */
@media screen and (max-width: 360px) {
  .menuContainer {
    margin-top: 170px;
    height: 220px;
    /* margin-bottom: -80px; */
    display: none;
  }
  .ham {
    margin-top: 220px;
    margin-bottom: -60px;
  }
}

/* $font-color: rgb(199, 182, 144); */
/* $font-color: #DA9B6B; */
.cardContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  width: 80%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto 150px auto;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 100px;
}

.card {
  width: 250px;
  height: 400px;
  /* background-color: rgb(42, 44, 43); */
  background-color: #FFE1A8;
  /* margin: 20px; */
  border-radius: 5px;
  border: 1px solid #FFE1A8;
  color: #0e1421;
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
          box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  position: relative;
  /* så att knappen kan sättas längst ned */
  z-index: -1;
}

.cardImage {
  width: 250px;
  -webkit-clip-path: polygon(2% 0, 98% 0, 103% 2%, 100% 2%, 100% 100%, 0 100%, 0 2%, -3% 2%);
          clip-path: polygon(2% 0, 98% 0, 103% 2%, 100% 2%, 100% 100%, 0 100%, 0 2%, -3% 2%);
}

.cardTitle {
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 900;
  line-height: 1.9rem;
  text-align: center;
}

.cardPara {
  width: 220px;
  margin: 10px auto 10px auto;
  text-align: left;
  line-height: 1.3rem;
  padding: 0 5px 0 5px;
}

.showMe {
  padding: 5px;
  z-index: 10;
  width: 150px;
  border-radius: 20px;
  color: white;
  /* $base-color; */
  background-color: #f12a0b;
  outline: 2px solid #f12a0b;
  outline-offset: 5px;
  margin: -40px auto 0 auto;
  text-align: center;
  font-weight: 900;
}

@media screen and (max-width: 920px) {
  .showMe {
    margin: -40px auto 0 auto;
    text-align: center;
  }
  .cardWrapper {
    gap: 130px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: auto;
    margin-bottom: 50px;
  }
}

/* base-color2 */
.punchLine {
  font-size: 130px;
  margin-top: 2rem;
  margin-left: 32px;
  /* position: absolute;
    top: 80px;
    left: 50px; */
  /* color: rgb(189, 195, 199); */
  color: #e4ebf0;
  text-shadow: 2px 2px 5px #16120c;
  font-family: 'Marcellus SC', serif;
  width: auto;
}

.punchSoft {
  width: auto;
  margin-left: 38px;
  font-size: 35px;
  color: #e4ebf0;
  text-shadow: 2px 2px 4px #0c0a07;
  font-family: 'Marcellus SC', serif;
}

@media screen and (max-width: 920px) {
  .punchLine {
    text-align: center;
    font-size: 70px;
  }
  .punchSoft {
    text-align: center;
    font-size: 25px;
  }
}

@media screen and (max-width: 576px) {
  .punchSoft {
    font-size: 20px;
  }
}

/* Huawei P Smart */
@media screen and (max-width: 360px) {
  .punchSoft {
    width: auto;
    margin: 0 auto;
    font-size: 15px;
  }
  .punchLine {
    font-size: 40px;
    width: auto;
    margin: 20px auto 0 auto;
  }
}

/* $base-color: rgb(9, 5, 10); */
/* $base-color: #21211F; */
/* $complement_color: rgb(176,146,152); */
/* $complement_color: #CD3C2A; */
/* $complement_color: #E26D5C; */
/* $complement_color: #da5641; */
/* css-reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*  */
body {
  background-color: #141413;
  color: #c7b690;
  width: 100%;
  /* height: 100vh; */
  font-family: 'Nunito Sans', sans-serif;
}

a {
  text-decoration: none;
}

/* ************************************** */
/* ************ HERO BILDEN ************* */
/* ************************************** */
.hero, .heroPortfolio, .heroContact, .heroAbout {
  background-image: url(../img/hero_land.webp);
  height: 40vh;
  width: 100%;
  position: absolute;
  top: 0;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-shadow: #141413 0px -30px 20px 12px inset;
          box-shadow: #141413 0px -30px 20px 12px inset;
  z-index: -1;
}

.heroPortfolio {
  background-image: url(../img/portfolio4.webp);
}

.heroContact {
  background-image: url(../img/contact.webp);
}

.heroAbout {
  background-image: url(../img/about.webp);
}

/* ************************************** */
/* ************ Container *************** */
/* ************************************** */
.flexContainer {
  /* hela en components innehåll */
  margin: 100px auto 50px auto;
  width: 80%;
  height: auto;
  /* display: flex;
	flex-wrap: wrap;
	justify-content: space-between; */
}

.article {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  height: auto;
  margin: 100px auto 100px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  gap: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.titles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 80px;
  margin-bottom: 50px;
}

.articleTitle, .articleTitleSecond {
  font-family: 'Marcellus SC', serif;
  font-size: 60px;
  text-align: center;
  color: #e4ebf0;
}

.articleTitleSecond {
  font-size: 30px;
  font-family: 'Nunito Sans', sans-serif;
}

.buttonContainer, .buttonContainer2 {
  /* background-color: $base-color2;
		background-color: $harmoni_color; */
  background-color: #f12a0b;
  text-decoration: none;
  font-weight: 900;
  letter-spacing: 3px;
  text-align: center;
  padding: 10px;
  width: 97%;
  /* height: 40px; */
  /* align-self: flex-end; */
  /* align-self: flex-start; */
  border-radius: 40px;
  outline: 2px solid #f12a0b;
  outline-offset: 5px;
  margin-top: 50px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.356) -2.4px -2.4px 3.2px inset;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.356) -2.4px -2.4px 3.2px inset;
}

.articleButton {
  color: white;
  /*  $base-color; */
  font-size: 30px;
}

.buttonContainer2 {
  width: 180px;
  height: 20px;
  margin-left: auto;
}

.articleButton2 {
  color: white;
  /* $base-color; */
  font-size: 20px;
}

.miniFlex, .miniFlex2 {
  width: 400px;
  height: 329px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.miniFlex2 {
  width: 97%;
  margin-bottom: -200px;
}

.articleContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 80px;
  max-width: 900px;
}

.selfImage {
  width: 350px;
  height: 250px;
}

.articleContent p {
  line-height: 1.2rem;
  margin: 10px 0 10px 0;
}

.dividerContainer {
  margin: 150px auto 50px auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* height: 30px; */
  max-width: 1200px;
}

.divider {
  width: 80%;
  opacity: 0.8;
}

.about {
  gap: 20px;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.aboutContent {
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  font-size: 1.2rem;
  max-width: 400px;
  margin-top: -6px;
}

@media screen and (max-width: 920px) {
  .articleContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
  }
  .selfImage {
    width: 350px;
    height: 350px;
    margin: 0 auto;
  }
  .buttonContainer2 {
    margin-top: 100px;
    width: 97%;
    height: auto;
    margin-left: 0;
    color: white;
  }
  .articleButton2 {
    color: #141413;
    font-size: 30px;
    color: white;
  }
  .miniFlex2 {
    margin-bottom: 0px;
  }
  .miniFlex, .miniFlex2 {
    width: 100%;
    height: auto;
    /* height: 329px;
		display: flex;
		flex-direction: column;
		justify-content: space-around; */
  }
}

@media screen and (max-width: 834px) {
  .about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .aboutContent {
    margin-top: -120px;
  }
}

@media screen and (max-width: 576px) {
  .titles {
    height: 80px;
    margin-bottom: 50px;
  }
  .articleTitleSecond {
    margin-top: 20px;
  }
  .articleTitle, .articleTitleSecond {
    font-size: 40px;
  }
  .articleTitleSecond {
    font-size: 25px;
  }
}

/* Huawei P Smart */
@media screen and (max-width: 360px) {
  .hero, .heroPortfolio, .heroContact, .heroAbout, .heroPortfolio, .heroContact, .heroAbout {
    height: 200px;
  }
  .titles {
    margin-bottom: 90px;
  }
}
