@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap');
$punch_color: rgb(228, 235, 240);  /* base-color2 */

.punchLine{
    font-size: 130px;
    margin-top: 2rem;
    margin-left: 32px;
    /* position: absolute;
    top: 80px;
    left: 50px; */
    /* color: rgb(189, 195, 199); */
    color: $punch_color;
    text-shadow: 2px 2px 5px rgb(22, 18, 12);
    font-family: 'Marcellus SC', serif;
    width: auto;
}
.punchSoft{
    width: auto;
    margin-left: 38px;
    font-size: 35px;
    color: $punch_color;
    text-shadow: 2px 2px 4px rgb(12, 10, 7);
    font-family: 'Marcellus SC', serif;
}


@media screen and (max-width: 920px){
   .punchLine{
       text-align:center;
       font-size: 70px;
   }
   .punchSoft{
       text-align:center;
       font-size: 25px;
       
   }
}


@media screen and (max-width: 576px){
    .punchSoft{
        
        font-size: 20px;
    }
}

/* Huawei P Smart */
@media screen and (max-width: 360px){
	.punchSoft{
		width: auto;
        margin: 0 auto;
		font-size: 15px;
	}
    .punchLine{
        
        font-size: 40px;
        width: auto;
        margin: 20px auto 0 auto;
    }
 }
