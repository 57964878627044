$nav-color: rgb(104, 89, 107);
/* $font-color: rgb(99,91,70); */
$font-color: rgb(199, 182, 144);
/* $harmoni_color: rgb(144,161,199); */
$harmoni_color: rgb(228, 235, 240);
.menuMain{
    display: flex;
    justify-content: flex-end;
    
}

.menuContainer{
    z-index: 1;
    width: auto;
    height: 35vh;  /* samma som herobild minus skugga. Ca-värde */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    /* font-family: 'Nunito Sans', sans-serif; */
    font-size: 20px;
    margin-right: 60px;
    text-align: right;
}
.ham{
    display: none;
}
.allnavs{
    text-decoration: none;
    font-weight: 900;
    letter-spacing:5px;
}
.navItem{
    @extend .allnavs;
    color: $harmoni_color;
    
}

.navItemOn{
    @extend .allnavs;
    color: black;
}

/* 768 */
@media screen and (max-width: 920px){
    .menuContainer{
        
        flex-direction: row;
        font-size: 20px;
        gap: 5px;
        width: 100%;
        margin-right: 0;
        height: 30px;
        margin-top: 400px;
    }
    .navItemOn{
        
        color: $harmoni_color;
    }

}


@media screen and (max-width: 576px){
    .menuContainer{
        display:none;
    }
    .menuContainer2{
        display:flex;
        gap: 25px;
        margin-top: 390px;
        flex-direction: column;
        font-size: 40px;
        height: 300px;
        text-align: center;
        margin-top:20px;
        line-height: 3.5rem;
        margin-bottom:-100px;
    }
    
    .ham{
        display: inline;
         margin: 0 auto;
        margin-top: 380px;
        margin-bottom: -50px;
        width: 100%;
        
    }
    .menuHam{
        margin: 0 auto;
        width: 35px;
        cursor: pointer;
        /* margin-left: auto; */
    }
    .bar1, .bar2, .bar3 {
        width: 35px;
        height: 5px;
        background-color: rgb(255, 255, 255);
        margin: 6px 0;
        transition: 0.4s;
        
      }
    .menuHamClose{
        cursor: pointer;
        width: 35px;
        height: 35px;
        border: 3px solid white;
        border-radius: 18px;
        margin: 0 auto;
    }
    
}

 /* Huawei P Smart */
 @media screen and (max-width: 360px){
    .menuContainer{
        margin-top: 170px;
        height:220px;
        /* margin-bottom: -80px; */
        display: none;
    }
    .ham{
        margin-top: 220px;
        margin-bottom:-60px;
    }
 }
